<template>
  <el-dialog class="operation_dialog" v-model="dialogVisible" :title="dialogtitle" :close-on-click-modal="false"
    width="550px">
    <!-- {{ props.baseInfo }}1{{ baseInfo }} -->
    <div class="dp-f" style="min-height: 200px;padding-top: 30px;">
      <el-form ref="ruleFormRef" @submit.native.prevent :model="ruleForm" :rules="rules" status-icon label-width="120px"
        class="demo-ruleForm w100" label-position="left">
        <el-form-item label="输入验证码：" prop='code' class="pt-r">
          <el-input v-model="ruleForm.code" :maxlength="config.inputMaxlength" show-word-limit placeholder="请输入验证码" />
          <verificationCode class="pt-a vcode" @Emit="vCodeEmit" ref="verificationCodeRef"></verificationCode>
        </el-form-item>
      </el-form>
    </div>
    <template #footer>
      <span class="dialog-footer dp-f fd-rr">
        <oabutton width='1000' height='40' style="border-radius: 4px;" title="确定" CSStype=2 @buttonclick="batchSeal()"
          v-model:loading="dialogloading"></oabutton>
      </span>
    </template>
  </el-dialog>
</template>
<script setup>

import { reactive, ref, unref, defineEmits, computed } from 'vue'
import { httpToken } from "@/utils/request";
import { handleCofirm, handleMessage } from "@/utils/server/confirm";
import { router_push_name } from "@/utils/server/router.js"
import { useStore } from "vuex";
import qs from "qs";
import verificationCode from '@/components/button/verificationCode.vue'//验证码组件
const store = useStore();
const props = defineProps({
  // 个人签名签署数据
  sealsignatures: {
    type: Array,
  },
  // 基础信息
  baseInfo: {
    type: Object,
  },
  // 签章签署数据
  sealsigns: {
    type: Array,
  },
  // 日期签署数据
  sealsigntimes: {
    type: Array,
  },
  basefilesList: {
    type: Array,
  },
});
const verificationCodeRef = ref()//验证码组件ref
const dialogloading = ref(false);// 弹框按钮loading 显示
const dialogtitle = ref();
const dialogVisible = ref(false)//弹框显示
// willExpression 意愿表达式(0: 不选择 1: 短信验证码 2: ukey证书 3: 密码验证 4: 人脸识别验证 )
const emit = defineEmits(['Emit'])
const id = ref(0);//操作的id
const state = reactive({
  newVerifyId: '',//当前缓存证书msg
  //当前签署人所有指定签署位置 0 签章，1 签名，2 日期，3 骑缝章
  sealpositionDatas: computed(() => {
    return store.state.sign.sealpositionDatas//签章
  }),
  signaturepositionDatas: computed(() => {
    return store.state.sign.signaturepositionDatas//签名
  }),
  datepositionDatas: computed(() => {
    return store.state.sign.datepositionDatas//日期
  }),
})
// /contract/signatureCode
const ruleFormRef = ref(null);//表单ref
const ruleForm = reactive({//from 表单数据
  code: '',//验证码
})
const rules = reactive({//from 表单校验
  code: [
    { required: true, message: '请输入验证码', trigger: 'blur' },
  ],
})
const vCodeEmit = () => {
  let signature_status = 0
  let brr = [...getdata(props.sealsignatures), ...getdata2(state.signaturepositionDatas)]
  if (brr.length > 0) {
    signature_status = 1
  }
  let arr = [...getdata(props.sealsigns), ...getdata2(state.sealpositionDatas)].map(v => {
    return v.sealId
  })
  httpToken({
    method: "post",
    url: '/initiate-contract/contract/signatureCode',
    data: qs.stringify({
      file_id: arr.join(','),
      contract_id: props.baseInfo.id,
      signature_status
    })
  }).then(res => {
    unref(verificationCodeRef).send()
    state.newVerifyId = res.msg
  })
}

const batchSeal = async () => {
  if (state.newVerifyId == '') {
    handleMessage('请先发送验证码')
    return
  }
  dialogloading.value = true
  const form = unref(ruleFormRef)
  if (!form) {
    dialogloading.value = false
    return
  }
  await form.validate((valid) => {
    if (valid) {
      httpToken({
        method: "post",
        url: '/initiate-contract/contract/PDFSignature',
        data: qs.stringify({
          contractId: props.baseInfo.id,//合同id
          code: ruleForm.code,
          signLocation: 'PC',
          newVerifyId: state.newVerifyId,
          map: JSON.stringify([...getdata(props.sealsigns), ...getdata2(state.sealpositionDatas), ...getdata(props.sealsigntimes), ...getdata2(state.datepositionDatas), ...getdata(props.sealsignatures), ...getdata2(state.signaturepositionDatas)])
        })
      }).then(res => {
        dialogloading.value = false
        dialogVisible.value = false
        handleMessage('签署成功', 'success')
        router_push_name('operation_sign', undefined, undefined, true)
      }).catch(() => {
        dialogloading.value = false
      })
    } else {
      dialogloading.value = false
    }
  })
}
const getdata = (signs) => {
  let data = []
  signs.forEach(item => {
    if (item.ishide != true) {
      let json={
        name: item.name,
        type: item.type,
        pdfId: props.basefilesList[item.basefilesIndex].id,
        sealId: item.sealId,
        X: item.signatureX,
        Y: item.signatureY,
        pageNum: item.pageIndex,
      }
      if(item.signNameType){
        json.signNameType=item.signNameType
      }
      data.push(json)
    }
  })
  return data
}
const getdata2 = (signs) => {
  let data = []
  signs.forEach(item => {
    if (item.nowSignData) {
      let json={
        name: item.nowSignData.name,
        type: item.nowSignData.type,
        pdfId: item.fileId,
        sealId: item.nowSignData.sealId,
        X: item.signatureX,
        Y: item.signatureY,
        pageNum: item.pageIndex,
      }
      if(item.signNameType){
        json.signNameType=item.signNameType
      }
      data.push(json)
    }
  })
  return data
}
const show = (() => {
  dialogloading.value = false
  dialogtitle.value = '签署意愿确认'
  dialogVisible.value = true
})

defineExpose({
  show,
});
</script>
<style lang="scss" scoped>
.vcode {
  z-index: 10;
  right: 18px;
}
</style>